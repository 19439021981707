import { useEffect, useState } from 'react'

const cache: Record<string, CanvasImageSource> = {}

const useLoadImage = ({ id, src, pageImage }: { id?: string; src: string; pageImage?: string }) => {
  const [image, setImage] = useState<CanvasImageSource | undefined>(undefined)
  const source = pageImage ? pageImage : src
  const cacheKey = id ? `${id}_${source}` : source

  useEffect(() => {
    if (cache[cacheKey]) {
      setImage(cache[cacheKey])
    } else {
      const img = new Image()
      img.src = source
      img.onload = () => {
        cache[cacheKey] = img
        img.style.borderRadius = '10px'
        setImage(img)
      }
      img.onerror = () => {
        img.src = src
      }
    }
  }, [pageImage, src])

  return image
}

export default useLoadImage
