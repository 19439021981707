import CANVAS_EVENT_CALL from '~/assets/images/canvas/canvas_event_call.svg'
import CANVAS_EVENT_CHECKOUT from '~/assets/images/canvas/canvas_event_checkout.svg'
import CANVAS_EVENT_DOWNLOAD from '~/assets/images/canvas/canvas_event_download.svg'
import CANVAS_EVENT_KWAI from '~/assets/images/canvas/canvas_event_kwai.svg'
import CANVAS_EVENT_LEAD from '~/assets/images/canvas/canvas_event_lead.svg'
import CANVAS_EVENT_PHONE from '~/assets/images/canvas/canvas_event_phone.svg'
import CANVAS_EVENT_SCHEDULE from '~/assets/images/canvas/canvas_event_schedule.svg'
// import CANVAS_EVENT_SPLIT from '~/assets/images/canvas/canvas_event_split.svg'
import CANVAS_EVENT_TABOOLA from '~/assets/images/canvas/canvas_event_taboola.svg'
import CANVAS_EVENT_THREADS from '~/assets/images/canvas/canvas_event_threads.svg'
import CANVAS_EVENT_X from '~/assets/images/canvas/canvas_event_x.svg'
import CANVAS_EVENT_ADS from '~/assets/images/canvas_event_ads.svg'
import CANVAS_EVENT_BUY from '~/assets/images/canvas_event_buy.svg'
import CANVAS_EVENT_EMAIL from '~/assets/images/canvas_event_email.svg'
import CANVAS_EVENT_FACEBOOK from '~/assets/images/canvas_event_facebook.svg'
import CANVAS_EVENT_INSTAGRAM from '~/assets/images/canvas_event_instagram.svg'
import CANVAS_EVENT_TELEGRAM from '~/assets/images/canvas_event_telegram.svg'
import CANVAS_EVENT_TIKTOK from '~/assets/images/canvas_event_tiktok.svg'
import CANVAS_EVENT_WPP from '~/assets/images/canvas_event_wpp.svg'
import CANVAS_EVENT_YOUTUBE from '~/assets/images/canvas_event_youtube.svg'

export const eventTypes = [
  {
    id: 'buy',
    name: 'Compra',
    type: 'event',
    src: CANVAS_EVENT_BUY,
  },
  {
    id: 'checkout',
    name: 'Carrinho',
    type: 'event',
    src: CANVAS_EVENT_CHECKOUT,
  },
  {
    id: 'email',
    name: 'Email',
    type: 'event',
    src: CANVAS_EVENT_EMAIL,
  },
  {
    id: 'ads',
    name: 'Ads',
    type: 'event',
    src: CANVAS_EVENT_ADS,
  },
  {
    id: 'facebook',
    name: 'Facebook',
    type: 'event',
    src: CANVAS_EVENT_FACEBOOK,
  },
  {
    id: 'instagram',
    name: 'Instagram',
    type: 'event',
    src: CANVAS_EVENT_INSTAGRAM,
  },
  {
    id: 'telegram',
    name: 'Telegram',
    type: 'event',
    src: CANVAS_EVENT_TELEGRAM,
  },
  {
    id: 'tiktok',
    name: 'Tiktok',
    type: 'event',
    src: CANVAS_EVENT_TIKTOK,
  },
  {
    id: 'wpp',
    name: 'WhatsApp',
    type: 'event',
    src: CANVAS_EVENT_WPP,
  },
  {
    id: 'youtube',
    name: 'Youtube',
    type: 'event',
    src: CANVAS_EVENT_YOUTUBE,
  },
  {
    id: 'call',
    name: 'Videochamada',
    type: 'event',
    src: CANVAS_EVENT_CALL,
  },
  {
    id: 'lead',
    name: 'Lead',
    type: 'event',
    src: CANVAS_EVENT_LEAD,
  },
  {
    id: 'kwai',
    name: 'Kwai',
    type: 'event',
    src: CANVAS_EVENT_KWAI,
  },
  {
    id: 'phone',
    name: 'Ligação',
    type: 'event',
    src: CANVAS_EVENT_PHONE,
  },
  {
    id: 'schedule',
    name: 'Agendamento',
    type: 'event',
    src: CANVAS_EVENT_SCHEDULE,
  },
  /* Disabled */
  // {
  //   id: 'split',
  //   name: 'Split',
  //   type: 'event',
  //   src: CANVAS_EVENT_SPLIT,
  // },
  {
    id: 'taboola',
    name: 'Taboola',
    type: 'event',
    src: CANVAS_EVENT_TABOOLA,
  },
  {
    id: 'threads',
    name: 'Threads',
    type: 'event',
    src: CANVAS_EVENT_THREADS,
  },
  {
    id: 'x',
    name: 'X',
    type: 'event',
    src: CANVAS_EVENT_X,
  },
  {
    id: 'download',
    name: 'Download',
    type: 'event',
    src: CANVAS_EVENT_DOWNLOAD,
  },
]
