// export const getFunnel = (id) => axios.get(`/funnels/${id}?_embed=pages,favicon_meta`)

import axios from '../../services/config/axios'
import { CanvasDto } from './dto'
import { dtoToCanvas } from './transform'

export async function getCanvas(canvasId: string) {
  const response = await axios.get<CanvasDto>(`/funnelcanvas/${canvasId}`)
  // first `.data` comes from axios
  const cavasDto = response.data
  return dtoToCanvas(cavasDto)
}

export async function getAllCanvas() {
  const response = await axios.get<{ data: CanvasDto[] }>(`/funnelcanvas`)

  return response.data.data
}

export async function saveCanvas(name: string) {
  const response = await axios.post(`/funnelcanvas`, { name })

  return response.data.data
}

export async function updateCanvas(canvasId: string, data: CanvasDto) {
  const response = await axios.put(`/funnelcanvas/${canvasId}`, data)

  return response.data.data
}

export async function removeCanvas(canvasId: string) {
  const response = await axios.delete(`/funnelcanvas/${canvasId}`)

  return response.data.data
}

export async function integrateFunnelToCanvas(canvasId: string, funnelId: string | null) {
  const response = await axios.put(`/funnelcanvas/${canvasId}/associate-funnel`, {
    funnel_id: funnelId,
  })

  const cavasDto = response.data
  return dtoToCanvas(cavasDto)
}

export async function disassociateFunnelToCanvas(canvasId: string) {
  const response = await axios.put(`/funnelcanvas/${canvasId}/disassociate-funnel`)

  const cavasDto = response.data
  return dtoToCanvas(cavasDto)
}
