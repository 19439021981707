// export const getFunnel = (id) => axios.get(`/funnels/${id}?_embed=pages,favicon_meta`)

import axios from '../../services/config/axios'
import { Domain, Funnel, PageMetrics } from './types'

export async function getFunnelWithPages(funnelId: string) {
  const response = await axios.get<{ data: Funnel }>(
    `/funnels/${funnelId}?_embed=pages,favicon_meta`,
  )
  // first `.data` comes from axios
  const { domain, pages, ...rest } = response.data.data
  return { funnel: rest, domain, pages }
}

export async function getAllFunnelsByDomain() {
  const response = await axios.get<{ data: Domain[] }>('/domains?_embed=funnels&created_at=desc')
  // first `.data` comes from axios
  const domains = response.data.data

  const funnels = domains.reduce((accumulator: Funnel[], currentValue) => {
    const arr = [...accumulator, ...(currentValue.funnels ?? [])]
    return arr
  }, [])

  return { funnels }
}

export async function getFunnelPagesMetrics(funnelId: string) {
  const response = await axios.get<{ data: PageMetrics[] }>(
    `/funnels/${funnelId}/metrics?_sort_by=step`,
  )
  const funnelPageMetrics = response.data.data

  return funnelPageMetrics
}
