import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
} from '@material-ui/core'
import styled from 'styled-components'

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: ${(props) => (props.isLarge ? '626px' : '557px')};
    width: ${(props) => (props.isLarge ? '626px' : '557px')};
  }
`

export const StyledDialogTitle = styled(DialogTitle)`
  margin: 0;
  padding: 16px 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e5e9f5;

  & p {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
  }
`

export const StyledDialogContent = styled(DialogContent)`
  padding: 16px 40px;

  & h6 {
    font-size: 18px;
    font-weight: 700;
    margin: 32px 0;
  }

  & .disclaimer {
    color: red; /* Adjust to your desired color */
    font-size: 14px;
    font-weight: 600;
  }
`

export const StyledBox = styled(Box)`
  margin: 25px 0 45px;

  & .autocomplete .MuiInputBase-root {
    padding: 0 !important;
  }

  & fieldset,
  input {
    height: 45px;
    border-radius: 8px;
    border: 2px solid #edf0f7;
  }

  & input {
    height: 40px;
  }

  & select,
  select:focus {
    background: transparent;
    height: 100%;
    padding: 0 10px;
  }

  & div {
    height: 40px;
  }
`

export const StyledLabel = styled(InputLabel)`
  font-size: 14px;
  font-weight: 400;
  color: #000000de;
  margin-bottom: 8px;
`

export const StyledActions = styled(DialogActions)`
  padding: 24px 40px;
  background: #f5f7ff;
`

export const StyledButton = styled(Button)`
  border-radius: 8px;
  border-width: 2px;
  margin: 0 15px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize !important;

  ${(props) =>
    props.variant === 'contained' &&
    `
    color: #fff;
    border-color: #4C6FFF;
    background-color: #4C6FFF;
    min-width: 130px;
    &:hover {
      border-color: #4C6FFF;
      border-width: 2px;
      background-color: #4C6FFF;
    }
    &:disabled {
      background-color: #ccc;
    }
    `}

  ${(props) =>
    props.variant === 'outlined' &&
    `
      color: #4C6FFF;
      min-width: 130px;
      border-color: #4C6FFF;
      border-width: 2px;
      &:hover {
        border-color: #4C6FFF;
        border-width: 2px;
      }
    `}

    ${(props) => props.disabled && `background-color: #ccc;`}
`

export const HelperContent = styled(DialogContent)`
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: space-around;

  & h6 {
    font-size: 16px;
    font-weight: 800;
    color: blue;
  }

  & p {
    font-size: 14px;
    color: blue;
  }

  & svg {
    margin-right: 25px;
  }
`
