import { Box, Button, FormControl, IconButton, Select, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import LinkIcon from '@material-ui/icons/Link'
import React, { useEffect } from 'react'

import { disassociateFunnelToCanvas, integrateFunnelToCanvas } from '~/api/canvas'
import { Canvas } from '~/api/canvas/types'
import { ConfirmationModal } from '~/components/ConfirmationModal'
import {
  StyledActions,
  StyledBox,
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledLabel,
  useModal,
} from '~/components/Modal'
import { useAlert } from '~/hooks/useAlert'

import { ActionTypes } from '../../context/BoardProvider'
import { useBoardState, useBoardUpdater } from '../../context/BoardProvider'
import { useFunnelsByDomain } from '../../hooks/useFunnelsByDomain'
import { resetPageId } from '../../utils'

export const FunnelIntegration = () => {
  const {
    board: { id: canvasId, funnelId: funnelIdFromCanvas, nodes },
  } = useBoardState()
  const { dispatch } = useBoardUpdater()
  const { open, toggle } = useModal()
  const { open: confirmationIsOpen, toggle: toggleConfirmation } = useModal()
  const [funnelId, setFunnelId] = React.useState<string>('')
  const { funnels } = useFunnelsByDomain()
  const { setAlert, getDataError } = useAlert()
  const currentFunnel = funnels?.find((funnel) => funnel.id.toString() === funnelId.toString())

  useEffect(() => {
    setFunnelId(funnelIdFromCanvas ?? '')
  }, [funnelIdFromCanvas])

  if (!funnels) return null

  const handleOnChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setFunnelId(event.target.value as string)
  }

  const handleIntegration = () => {
    if (!funnelId) return
    toggle()
    integrateFunnelToCanvas(canvasId, funnelId)
      .then((response) => {
        setAlert('Funil atribuído com sucesso', 'success')
        const { funnelId } = response
        const nodesWithoutPageId = resetPageId(nodes)
        dispatch({
          type: ActionTypes.UPDATE_FUNNEL_ID,
          payload: { funnelId, nodes: nodesWithoutPageId } as Canvas,
        })
      })
      .catch((error) => {
        const message = getDataError(error)
        console.error(message)

        setAlert(' Houve um problema nesta operação. Por favor tente mais tarde.', 'error')
      })
  }

  const handleRemoveIntegration = () => {
    setFunnelId('')
    disassociateFunnelToCanvas(canvasId).then((response) => {
      setAlert('Funil removido com sucesso', 'success')
      const { funnelId } = response
      const nodesWithoutPageId = resetPageId(nodes)
      dispatch({
        type: ActionTypes.UPDATE_FUNNEL_ID,
        payload: { funnelId, nodes: nodesWithoutPageId } as Canvas,
      })
    })
  }

  return (
    <>
      <StyledDialog open={open} onClose={toggle}>
        <StyledDialogTitle disableTypography>
          <Typography component="p">Atribuir Funil</Typography>
          <IconButton onClick={toggle}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>

        <StyledDialogContent>
          <StyledBox>
            <Box style={{ height: '100%', margin: '36px 0px 0px' }}>
              <StyledLabel htmlFor="integrationFunnelId">Selecionar funil</StyledLabel>
              <FormControl fullWidth>
                <Select
                  native
                  variant="outlined"
                  fullWidth
                  name="integrationFunnelId"
                  value={funnelId}
                  onChange={handleOnChange}
                >
                  <option value="" disabled>
                    Selecionar funil
                  </option>
                  {funnels.map((funnel, index) => (
                    <option key={`${funnel.id}_${index}`} value={funnel.id}>
                      {funnel.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </StyledBox>
        </StyledDialogContent>
        <StyledActions>
          <StyledButton variant="outlined" onClick={toggle}>
            Cancelar
          </StyledButton>
          <StyledButton variant="contained" onClick={handleIntegration}>
            Confirmar
          </StyledButton>
        </StyledActions>
      </StyledDialog>
      {funnelIdFromCanvas ? (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={'1rem'}>
          <Typography variant="caption">{currentFunnel?.name}</Typography>
          <Button color="secondary" onClick={() => toggleConfirmation()} startIcon={<LinkIcon />}>
            Desvincular funil
          </Button>
        </Box>
      ) : (
        <Button variant="outlined" onClick={toggle} startIcon={<LinkIcon />}>
          Atribuir funil
        </Button>
      )}
      <ConfirmationModal
        isOpen={confirmationIsOpen}
        handleClose={toggleConfirmation}
        onClickConfirmation={() => {
          handleRemoveIntegration()
          toggleConfirmation()
        }}
      />
    </>
  )
}
