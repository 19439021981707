import CANVAS_PAGE_BLOG from '~/assets/images/canvas/canvas_page_blog.svg'
import CANVAS_PAGE_DOWNLOAD from '~/assets/images/canvas/canvas_page_download.svg'
import CANVAS_PAGE_MEMBERS from '~/assets/images/canvas/canvas_page_members.svg'
import CANVAS_PAGE_QUIZ from '~/assets/images/canvas/canvas_page_quiz.svg'
import CANVAS_PAGE_SALES2 from '~/assets/images/canvas/canvas_page_sales2.svg'
import CANVAS_PAGE_SCHEDULE from '~/assets/images/canvas/canvas_page_schedule.svg'
import CANVAS_PAGE_DOWNSELL from '~/assets/images/canvas_page_downsell.svg'
import CANVAS_PAGE_LEAD from '~/assets/images/canvas_page_lead.svg'
import CANVAS_PAGE_OTHERS from '~/assets/images/canvas_page_others.svg'
import CANVAS_PAGE_SALES from '~/assets/images/canvas_page_sales.svg'
import CANVAS_PAGE_THANKS from '~/assets/images/canvas_page_thanks.svg'
import CANVAS_PAGE_UPSELL from '~/assets/images/canvas_page_upsell.svg'

export const pageTypes = [
  {
    id: 'download',
    name: 'Downsell',
    type: 'page',
    src: CANVAS_PAGE_DOWNSELL,
  },
  {
    id: 'sales',
    name: 'Vendas',
    type: 'page',
    src: CANVAS_PAGE_SALES,
  },
  {
    id: 'others',
    name: 'Obrigado',
    type: 'page',
    src: CANVAS_PAGE_THANKS,
  },
  {
    id: 'lead',
    name: 'Captura',
    type: 'page',
    src: CANVAS_PAGE_LEAD,
  },
  {
    id: 'thanks',
    name: 'Upsell',
    type: 'page',
    src: CANVAS_PAGE_UPSELL,
  },
  {
    id: 'upsell',
    name: 'Diversa',
    type: 'page',
    src: CANVAS_PAGE_OTHERS,
  },
  {
    id: 'schedule',
    name: 'Agenda',
    type: 'page',
    src: CANVAS_PAGE_SCHEDULE,
  },
  {
    id: 'sales2',
    name: 'Checkout',
    type: 'page',
    src: CANVAS_PAGE_SALES2,
  },
  {
    id: 'members',
    name: 'Área de Membros',
    type: 'page',
    src: CANVAS_PAGE_MEMBERS,
  },
  {
    id: 'blog',
    name: 'Blog',
    type: 'page',
    src: CANVAS_PAGE_BLOG,
  },
  {
    id: 'quiz',
    name: 'Quiz',
    type: 'page',
    src: CANVAS_PAGE_QUIZ,
  },
  {
    id: 'download',
    name: 'Download',
    type: 'page',
    src: CANVAS_PAGE_DOWNLOAD,
  },
]
