export const ADD_NODE = 'add-node'
export const REMOVE_NODE = 'remove-node'
export const COPY_NODE = 'copy-node'
export const ADD_CONNECTION = 'add-connection'
export const REMOVE_CONNECTION = 'remove-connection'
export const UPDATE_POSITIONS = 'update-positions'
export const UPDATE_NODE = 'update-node'
export const SET = 'set'
export const EVENT_TYPE = 'event'
export const PAGE_TYPE = 'page'
