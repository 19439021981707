import Konva from 'konva'
import React, { useEffect, useRef, useState } from 'react'
import { Rect, Text, Transformer } from 'react-konva'

import { Node } from '~/api/canvas/types'

import { ActionTypes } from '../../context/BoardProvider'
import { useBoardState, useBoardUpdater } from '../../context/BoardProvider'
import { Delete } from '../Actions'
import { Html } from '../Html'

export const EditableText = ({
  element,
  isSelected,
  onDragMove,
  handleClick,
}: {
  element: Node
  isSelected: boolean
  onDragMove: (e: Konva.KonvaEventObject<DragEvent>) => void
  handleClick: () => void
}) => {
  const { id, position, size: initialSize } = element
  const { board } = useBoardState()
  const elementText = board.nodes.find((el) => el.id === id)

  const [isEditing, setIsEditing] = useState(false)
  const [text, setText] = useState(elementText?.text || 'Escreva sua nota aqui...')
  const [inputValue, setInputValue] = useState(text)
  const [size, setSize] = useState(
    initialSize || {
      width: 220,
      height: 200,
    },
  )
  const [textareaStyle, setTextareaStyle] = useState({})
  const textRef = useRef<Konva.Text>(null)
  const transformerRef = useRef<Konva.Transformer>(null)
  const { dispatch } = useBoardUpdater()

  // Handle double-click or double-tap to start editing
  const handleDoubleClick = () => {
    setIsEditing(true)
    setInputValue(text)

    const textNode = textRef.current
    if (!textNode || !position) return

    // Position the textarea over the text node
    const textareaStyle = {
      position: 'absolute',
      top: position.y + 'px',
      left: position.x + 'px',
      width: textNode.width() + 'px',
      height: textNode.height() + 'px',
      fontSize: textNode.fontSize() + 'px',
      border: 'none',
      padding: '0px',
      margin: '0px',
      overflow: 'hidden',
      background: 'white',
      outline: 'none',
      resize: 'none',
      lineHeight: textNode.lineHeight(),
      fontFamily: textNode.fontFamily(),
      textAlign: textNode.align(),
      color: textNode.fill(),
    }
    setTextareaStyle(textareaStyle)
  }

  const handleNameChange = () => {
    dispatch({
      type: ActionTypes.UPDATE_NODE,
      payload: {
        ...element,
        text: inputValue,
        name: inputValue,
      },
    })
  }

  // Handle exiting editing mode (blur or Enter key)
  const handleTextareaBlur = () => {
    handleNameChange()
    setText(inputValue)
    setIsEditing(false)
  }

  const onTransform = () => {
    const textNode = textRef.current
    if (!textNode) return

    const width = textNode.width() * textNode.scaleX()
    const height = textNode.height() * textNode.scaleY()

    setSize({
      width,
      height,
    })

    textNode.setAttrs({
      width,
      height,
      scaleX: 1,
      scaleY: 1,
    })

    dispatch({
      type: ActionTypes.UPDATE_NODE,
      payload: {
        ...element,
        size,
      },
    })
  }

  useEffect(() => {
    if (transformerRef.current && textRef.current) {
      transformerRef.current.nodes([textRef.current])
      transformerRef.current.getLayer()?.batchDraw()
    }
  }, [])

  return (
    <>
      <Rect
        x={position?.x}
        y={position?.y}
        fill="#FFFAE6"
        width={size.width}
        height={size.height}
        // perfectDrawEnabled={false}
        cornerRadius={[8, 8, 8, 8]}
      />
      {!isEditing && isSelected && (
        <>
          <Rect
            x={position?.x}
            y={position?.y ? position.y - 20 : 0}
            fill="#1facff"
            width={25}
            height={20}
            cornerRadius={[2, 2, 0, 0]}
          />
          <Delete x={position?.x || 0} y={position?.y || 0} id={id} />
        </>
      )}
      <Text
        ref={textRef}
        text={text}
        padding={10}
        fill="#828282"
        x={position?.x}
        y={position?.y}
        fontSize={14}
        draggable
        width={size.width}
        height={size.height}
        onClick={handleClick}
        onDblClick={handleDoubleClick} // Activate editing on double-click
        onDragMove={onDragMove}
      />
      <Transformer
        ref={transformerRef}
        rotateEnabled={false}
        enabledAnchors={['top-center', 'bottom-center', 'middle-left', 'middle-right']}
        boundBoxFunc={(oldBox, newBox) => {
          newBox.width = Math.max(30, newBox.width) // Minimum width constraint
          return newBox
        }}
        onTransform={onTransform}
        visible={!isEditing && isSelected}
      />
      {isEditing && (
        <Html>
          <textarea
            style={textareaStyle}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={handleTextareaBlur}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                handleTextareaBlur()
              }
              if (e.key === 'Escape') {
                setIsEditing(false) // Exit editing without saving
              }
            }}
            autoFocus
          />
        </Html>
      )}
    </>
  )
}
