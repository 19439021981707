/* eslint-disable react/prop-types */

import Konva from 'konva'
import { Stage } from 'konva/lib/Stage'
import React, { useEffect } from 'react'
import uuid from 'react-uuid'

import { getCanvas } from '~/api/canvas'
import { Canvas, Node } from '~/api/canvas/types'
import { getFunnelPagesMetrics, getFunnelWithPages } from '~/api/funnels'
import { FunnelWithPages, PageMetrics } from '~/api/funnels/types'
import { useLoadData } from '~/hooks'

export type Position = {
  x: number
  y: number
}

export enum ActionTypes {
  ADD_NODE = 'add-node',
  REMOVE_NODE = 'remove-node',
  COPY_NODE = 'copy-node',
  ADD_CONNECTION = 'add-connection',
  REMOVE_CONNECTION = 'remove-connection',
  UPDATE_POSITIONS = 'update-positions',
  UPDATE_NODE = 'update-node',
  SET = 'set',
  EVENT_TYPE = 'event',
  PAGE_TYPE = 'page',
  UPDATE_FUNNEL_ID = 'update-funnel-id',
}

interface BoardProviderProps {
  boardId: string
  children: React.ReactNode
}

type NodePayload = Node & { to?: string; matchId?: string; metrics?: PageMetrics }
export type ActionReducer = NodePayload | Canvas | Node[]
export type BoardStateContextType =
  | {
      board: Canvas
      isFetching: boolean
      stageRef: { current: Stage | null }
      elementSelected: Node | null
      funnel: FunnelWithPages | null
      funnelPageMetrics: PageMetrics[] | null
    }
  | undefined

export type BoardUpdaterContextType =
  | {
      dispatch: React.Dispatch<{
        type: ActionTypes
        payload: ActionReducer
      }>
      runRequest: () => void
      getStageCenter: () => Position
      getPointWithScale: (pointer: Position) => Position
      setElementSelected: React.Dispatch<React.SetStateAction<Node | null>>
    }
  | undefined

const BoardStateContext = React.createContext<BoardStateContextType>(undefined)
const BoardUpdaterContext = React.createContext<BoardUpdaterContextType>(undefined)

const getElementIndex = (id: string, nodes: Node[]) => nodes.findIndex((el) => el.id === id)

function boardReducer(
  state: Canvas,
  action: { type: ActionTypes; payload: ActionReducer },
): Canvas {
  switch (action.type) {
    case ActionTypes.ADD_NODE: {
      if ('type' in action.payload) {
        const nodesUpdated = [
          ...state.nodes,
          {
            ...action.payload,
            id: uuid(),
            match: [],
          },
        ]

        return { ...state, nodes: nodesUpdated }
      }
      return { ...state }
    }
    case ActionTypes.REMOVE_NODE: {
      if ('id' in action.payload) {
        const { id } = action.payload

        let nodesCopy = [...state.nodes]
        nodesCopy = nodesCopy.map((el) => {
          if (el.match) {
            const index = el.match.indexOf(id)
            if (index !== -1) {
              el.match.splice(index, 1)
            }
          }
          return el
        })
        const nodesUpdated = nodesCopy.filter((el) => el.id !== id)

        return { ...state, nodes: nodesUpdated }
      }
      return { ...state }
    }
    case ActionTypes.COPY_NODE: {
      if ('position' in action.payload) {
        const { id, position } = action.payload
        const index = getElementIndex(id, state.nodes)
        let nodesUpdated = [...state.nodes]

        nodesUpdated = [
          ...state.nodes,
          {
            ...nodesUpdated[index],
            id: uuid(),
            position: position,
            match: [],
          },
        ]

        return { ...state, nodes: nodesUpdated }
      }
      return { ...state }
    }
    case ActionTypes.ADD_CONNECTION: {
      if ('to' in action.payload) {
        const { id, to } = action.payload
        const nodesUpdated = [...state.nodes]
        const index = getElementIndex(id, state.nodes)

        if (nodesUpdated[index].match && to) {
          nodesUpdated[index] = {
            ...nodesUpdated[index],
            match: [...(nodesUpdated[index].match ?? []), to],
          }
        }

        return { ...state, nodes: nodesUpdated }
      }
      return { ...state }
    }
    case ActionTypes.REMOVE_CONNECTION: {
      if ('matchId' in action.payload) {
        const { id, matchId } = action.payload
        const nodesUpdated = [...state.nodes]
        const elementIndex = nodesUpdated.findIndex((el) => el.id === id)

        if (nodesUpdated[elementIndex].match && matchId) {
          const index = (nodesUpdated[elementIndex].match ?? []).indexOf(matchId)
          if (index !== -1) {
            const arr = nodesUpdated[elementIndex].match ?? []
            arr.splice(index, 1)
          }
        }
        return { ...state, nodes: nodesUpdated }
      }
      return { ...state }
    }
    case ActionTypes.UPDATE_POSITIONS: {
      if (Array.isArray(action.payload)) {
        return { ...state, nodes: action.payload as Node[] }
      }
      return { ...state }
    }
    case ActionTypes.UPDATE_NODE: {
      if ('id' in action.payload) {
        const { id } = action.payload
        const nodesUpdated = [...state.nodes]
        const index = getElementIndex(id, state.nodes)

        nodesUpdated[index] = {
          ...nodesUpdated[index],
          ...action.payload,
        }

        return { ...state, nodes: nodesUpdated }
      }
      return { ...state }
    }
    case ActionTypes.SET: {
      if (action.payload && 'nodes' in action.payload) {
        return { ...state, ...action.payload }
      }
      return { ...state }
    }
    case ActionTypes.UPDATE_FUNNEL_ID: {
      if (action.payload && 'funnelId' in action.payload) {
        return { ...state, funnelId: action.payload.funnelId, nodes: action.payload.nodes }
      }
      return { ...state }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function BoardProvider({ boardId, children }: BoardProviderProps) {
  const { data, isFetching, runRequest } = useLoadData(() => getCanvas(boardId))
  const [board, dispatch] = React.useReducer(boardReducer, {
    id: '',
    funnelId: '',
    name: '',
    nodes: [],
  })
  const [elementSelected, setElementSelected] = React.useState<Node | null>(null)
  const stageRef = React.useRef<Konva.Stage>(null)
  const [funnel, setFunnel] = React.useState<FunnelWithPages | null>(null)
  const [funnelPageMetrics, setFunnelPageMetrics] = React.useState<PageMetrics[] | null>(null)

  useEffect(() => {
    if (board.funnelId) {
      getFunnelWithPages(board.funnelId).then((data) => setFunnel(data))
      getFunnelPagesMetrics(board.funnelId).then((data) => setFunnelPageMetrics(data))
    } else {
      setFunnel(null)
    }
  }, [board.funnelId])

  useEffect(() => {
    if (!board?.id) {
      dispatch({ type: ActionTypes.SET, payload: data as Canvas })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  const getStageCenter = (): Position => {
    const stage = stageRef.current
    if (!stage) return { x: 0, y: 0 }
    const scale = stage.scaleX()

    const pointer = {
      x: stage.width() / 2,
      y: stage.height() / 2,
    }

    const mousePointTo = {
      x: (pointer.x - stage.x()) / scale,
      y: (pointer.y - stage.y()) / scale,
    }

    return {
      x: mousePointTo.x,
      y: mousePointTo.y,
    }
  }

  const getPointWithScale = (pointer: Position): Position => {
    const stage = stageRef.current
    if (!stage) return { x: 0, y: 0 }

    const scale = stage?.scaleX()

    const mousePointTo = {
      x: (pointer.x - stage.x()) / scale,
      y: (pointer.y - stage.y()) / scale,
    }

    return {
      x: mousePointTo.x,
      y: mousePointTo.y,
    }
  }

  const state = {
    board,
    isFetching,
    stageRef,
    elementSelected,
    funnel,
    funnelPageMetrics,
  }

  const updater = {
    dispatch,
    runRequest,
    getStageCenter,
    getPointWithScale,
    setElementSelected,
  }

  return (
    <BoardStateContext.Provider value={state}>
      <BoardUpdaterContext.Provider value={updater}>{children}</BoardUpdaterContext.Provider>
    </BoardStateContext.Provider>
  )
}

function useBoardState() {
  const context = React.useContext(BoardStateContext)
  if (context === undefined) {
    throw new Error('useBoardState must be used within a BoardProvider')
  }
  return context
}

function useBoardUpdater() {
  const context = React.useContext(BoardUpdaterContext)
  if (context === undefined) {
    throw new Error('useBoardUpdater must be used within a BoardProvider')
  }
  return context
}

export { BoardProvider, getElementIndex, useBoardState, useBoardUpdater }
