/* eslint-disable react/prop-types */
import React from 'react'
import { Image } from 'react-konva'

import DeleteIcon from '~/assets/images/delete_icon.svg'

import { ActionTypes, useBoardUpdater } from '../../context/BoardProvider'
import useLoadImage from '../../hooks/useLoadImage'

function Delete({ x, y, id }: { x: number; y: number; id: string }) {
  const image = useLoadImage({ src: DeleteIcon })
  const { dispatch } = useBoardUpdater()
  const width = 18

  const handleOnClick = () => {
    dispatch({
      type: ActionTypes.REMOVE_NODE,
      payload: { id },
    })
  }

  return (
    <>
      <Image
        x={x + 4}
        y={y - width}
        width={width}
        height={width}
        onClick={handleOnClick}
        cornerRadius={2}
        image={image}
        onMouseEnter={(e) => {
          const container = e.target.getStage()?.container()
          if (container) {
            container.style.cursor = 'pointer'
          }
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()?.container()
          if (container) {
            container.style.cursor = 'default'
          }
        }}
      />
    </>
  )
}

export default Delete
