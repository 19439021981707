/* eslint-disable react/prop-types */
import { Image as ImageType } from 'konva/lib/shapes/Image'
import React, { forwardRef } from 'react'
import { Group, Line, Rect, Text } from 'react-konva'

import { Metrics } from '~/api/funnels/types'

import { ELEMENT_PAGE } from '../../constants/elementsType'
import { Position } from '../../context/BoardProvider'
import { Node } from './Node'
import { NodeProps } from './types'

interface LabelProps {
  position: Position
  metrics: Metrics
}
const Label = ({ position, metrics }: LabelProps) => {
  const labelWidth = 50 // Width of the rounded rectangle
  const labelHeight = 40 // Height of the rounded rectangle
  const labelPadding = 8 // Padding between the image and the label
  const cornerRadius = 6 // Border radius for the rectangle
  const arrowHeight = 6 // Height of the arrow
  const arrowWidth = 10 // Width of the arrow at the base

  // Rectangle position
  const rectX = position.x + (ELEMENT_PAGE.dimension.w - labelWidth) / 2
  const rectY = position.y + ELEMENT_PAGE.dimension.h + labelPadding

  // Arrow points
  const arrowBaseY = rectY // Arrow base (bottom of arrow)
  const arrowTipY = rectY - arrowHeight // Arrow tip (top of arrow)
  const arrowCenterX = rectX + labelWidth / 2 // Arrow center horizontally

  return (
    <Group>
      <Rect
        x={position.x + (ELEMENT_PAGE.dimension.w - labelWidth) / 2} // Centered below the image
        y={position.y + ELEMENT_PAGE.dimension.h + labelPadding} // Positioned below the image
        width={labelWidth}
        height={labelHeight}
        fill="#ffffff" // Background color
        shadowColor="rgba(0, 0, 0, 0.1)" // Optional shadow for better visuals
        shadowBlur={5}
        cornerRadius={cornerRadius}
      />
      <Text
        x={position.x + (ELEMENT_PAGE.dimension.w - labelWidth) / 2} // Centered within the rectangle
        y={position.y + ELEMENT_PAGE.dimension.h + labelPadding + (labelHeight - 26) / 2} // Centered vertically
        width={labelWidth}
        text={`Visitas`} // Text to display
        align="center"
        verticalAlign="middle"
        fontSize={10}
        fill="#757575" // Text color
      />
      <Text
        x={position.x + (ELEMENT_PAGE.dimension.w - labelWidth) / 2} // Centered within the rectangle
        y={position.y + ELEMENT_PAGE.dimension.h + labelPadding + (labelHeight + 8) / 2} // Centered vertically
        width={labelWidth}
        text={`${metrics.visits}`} // Text to display
        align="center"
        verticalAlign="middle"
        fontSize={12}
        fill="#000000" // Text color
      />
      <Line
        points={[
          arrowCenterX - arrowWidth / 2,
          arrowBaseY, // Left point of arrow base
          arrowCenterX + arrowWidth / 2,
          arrowBaseY, // Right point of arrow base
          arrowCenterX,
          arrowTipY, // Arrow tip
        ]}
        closed // Closes the shape
        fill="#ffffff" // Arrow fill color
        stroke="#ffffff" // Optional border for the arrow
        strokeWidth={1}
        shadowColor="rgba(0, 0, 0, 0.1)" // Optional shadow for the arrow
        shadowBlur={5}
      />
    </Group>
  )
}

export const NodePage = forwardRef(function NodePage(props: NodeProps, ref: React.Ref<ImageType>) {
  const {
    element: { position = { x: 0, y: 0 } },
    metrics,
  } = props

  return (
    <>
      <Node {...props} ref={ref} />
      {metrics && <Label position={position} metrics={metrics} />}
    </>
  )
})
