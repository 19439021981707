import { useEffect, useState } from 'react'

import { getAllFunnelsByDomain } from '~/api/funnels'
import { Funnel } from '~/api/funnels/types'

export const useFunnelsByDomain = () => {
  const [funnels, setFunnels] = useState<Funnel[] | null>(null)

  useEffect(() => {
    getAllFunnelsByDomain().then((data) => setFunnels(data.funnels))
  }, [])

  return { funnels }
}
