/* eslint-disable react/prop-types */
import { FormControl, Select, TextField, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React, { ChangeEvent } from 'react'
import styled from 'styled-components'

import { ReactComponent as UpGraphIconSVG } from '~/assets/images/up_graph.svg'
import { ReactComponent as VisitsIconSVG } from '~/assets/images/visits.svg'
import { StyledLabel } from '~/components/Modal'

import { ActionTypes } from '../../context/BoardProvider'
import { useBoardState, useBoardUpdater } from '../../context/BoardProvider'
import { getMetrics } from '../../utils'
import { StyledBox } from './BoardStyles'

const VisitsIcon = styled(VisitsIconSVG)`
  path {
    text-align: center;
  }
`

export const UpGraphIcon = styled(UpGraphIconSVG)`
  path {
    text-align: center;
  }
`

const MetricItem = ({
  metricTitle,
  metricValue,
  style,
}: {
  metricTitle: string
  metricValue: number
  style?: Record<string, unknown>
}) => (
  <Box display="flex" justifyContent="flex-start" alignItems="center" gridGap="1rem">
    <VisitsIcon />
    <Box>
      <Typography variant="body2" style={{ fontSize: '14px', color: '#929699' }}>
        {metricTitle}
      </Typography>
      <Typography variant="h4" style={{ fontSize: '22px', fontWeight: 200, ...style }}>
        {String(metricValue)}
      </Typography>
    </Box>
  </Box>
)

// Visitas na página
// String(metrics?.visits)

export function SidebarRight() {
  const { elementSelected, board, funnel, funnelPageMetrics } = useBoardState()
  const { id } = elementSelected ?? {}
  const element = board.nodes.find((el) => el.id === id)
  const { dispatch } = useBoardUpdater()
  const { pages } = funnel ?? {}
  const isPageType = element?.type === 'page'
  const { metrics } = getMetrics(funnelPageMetrics, element?.pageId) ?? {}

  if (!element) return null

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionTypes.UPDATE_NODE,
      payload: {
        ...element,
        name: e.target.value,
      },
    })
  }

  const handlePageChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    dispatch({
      type: ActionTypes.UPDATE_NODE,
      payload: {
        ...element,
        pageId: e.target.value as string,
      },
    })
  }

  return (
    <>
      <Box m="1rem">
        <StyledLabel>Nome</StyledLabel>
        <TextField
          variant="outlined"
          autoComplete="off"
          value={element?.name}
          type="text"
          onChange={handleNameChange}
          fullWidth
        />
      </Box>
      {isPageType && pages && (
        <Box m="1rem">
          <StyledLabel>Página</StyledLabel>
          <FormControl fullWidth>
            <Select
              native
              variant="outlined"
              fullWidth
              name="integrationFunnelId"
              value={element.pageId ?? ''}
              onChange={handlePageChange}
            >
              <option value="" disabled>
                Selecionar uma página
              </option>
              {pages &&
                pages.map((page, index) => (
                  <option key={`${page.id}_${index}`} value={page.id}>
                    {page.name}
                  </option>
                ))}
            </Select>
          </FormControl>
          <Typography variant="caption" style={{ fontSize: '12px', color: '#929699' }}>
            Atribua uma página do seu funil a este elemento para ver suas métricas.
          </Typography>
        </Box>
      )}
      <StyledBox py="1rem">
        {metrics && (
          <>
            <Box mx="1rem" pb="0.3rem">
              <StyledLabel>Métricas</StyledLabel>
            </Box>
            <Box pb="1rem" borderBottom={'1px solid lightgray'}>
              <Box mx="1rem">
                <MetricItem
                  metricTitle="Visitas totais na página"
                  metricValue={metrics?.visits}
                  style={{ fontWeight: 400 }}
                />
              </Box>
            </Box>
            <Box mx="1rem" pt="1rem">
              <Box pb="1rem">
                <MetricItem
                  metricTitle="Visitas de Acesso direto"
                  metricValue={metrics?.direct_visits}
                />
              </Box>
              <Box pb="1rem">
                <MetricItem
                  metricTitle="Visitas de Sites Externos"
                  metricValue={metrics?.external_visits}
                />
              </Box>
              <Box pb="1rem">
                <MetricItem
                  metricTitle="Visitas de outras páginas da MakeFunnels"
                  metricValue={metrics?.funnel_visits}
                />
              </Box>
            </Box>
          </>
        )}
      </StyledBox>
    </>
  )
}
