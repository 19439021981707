/* eslint-disable react/prop-types */
import { Image as ImageType } from 'konva/lib/shapes/Image'
import { Text as TextType } from 'konva/lib/shapes/Text'
import React, { forwardRef, useRef } from 'react'
import { Image, Text } from 'react-konva'

import { EVENT_TYPE } from '../../constants'
import { ELEMENT_EVENT, ELEMENT_PAGE } from '../../constants/elementsType'
import useLoadImage from '../../hooks/useLoadImage'
import { Border } from '../Border'
import { NodeProps } from './types'

export const Node = forwardRef(function NodeBase(
  {
    element,
    isSelected,
    onDragMove,
    handleClick,
    handleAnchorDragEnd,
    handleAnchorDragMove,
    handleAnchorDragStart,
    handleDblClick,
  }: NodeProps,
  ref: React.Ref<ImageType>,
) {
  const { id, name, type, src = '', pageImage = '', position = { x: 0, y: 0 } } = element
  const image = useLoadImage({ id, src, pageImage })
  const isEventType = type === EVENT_TYPE
  const width = isEventType ? ELEMENT_EVENT.dimension.w : ELEMENT_PAGE.dimension.w
  const height = isEventType ? ELEMENT_EVENT.dimension.h : ELEMENT_PAGE.dimension.h
  const textHeight = useRef<TextType | null>(null)

  const textPosition = {
    x: isSelected ? position.x + 46 : position.x,
    y: position.y - (textHeight.current?.height() || 0),
  }

  return (
    <>
      {isSelected && (
        <Border
          id={id}
          position={position}
          width={width}
          height={height}
          onAnchorDragEnd={handleAnchorDragEnd}
          onAnchorDragMove={handleAnchorDragMove}
          onAnchorDragStart={handleAnchorDragStart}
        />
      )}
      <Text
        ref={textHeight}
        x={textPosition.x}
        y={textPosition.y}
        width={width}
        text={name}
        align={isSelected ? 'left' : 'center'}
        fontStyle="normal"
      />
      <Image
        ref={ref}
        x={position.x}
        y={position.y}
        width={width}
        height={height}
        draggable
        onClick={handleClick}
        onDblClick={handleDblClick}
        onDragMove={onDragMove}
        image={image}
        onMouseEnter={(e) => {
          const container = e.target.getStage()?.container()
          if (container) {
            container.style.cursor = 'pointer'
          }
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()?.container()
          if (container) {
            container.style.cursor = 'default'
          }
        }}
      />
    </>
  )
})
