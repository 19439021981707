import { Cancel as CancelIcon, Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons'
import styled, { css } from 'styled-components'

const sharedStyle = css`
  font-size: 1.2rem;
`

export const CancelIconStyled = styled(CancelIcon)`
  ${sharedStyle}/* some non-shared styles */
`
export const EditIconStyled = styled(EditIcon)`
  ${sharedStyle}/* some non-shared styles */
`
export const SaveIconStyled = styled(SaveIcon)`
  ${sharedStyle}/* some non-shared styles */
`
