import { IconButton } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import AddNoteSVG from '~/assets/images/add-note.svg'

import { ActionTypes, useBoardUpdater } from '../../context/BoardProvider'

type AddNoteButtonProps = {
  drawerWidth: number
  show: boolean
}

const StyledAddNoteButton = styled.div<AddNoteButtonProps>`
  margin-right: 2rem;
  position: absolute;
  top: 80px;
  left: ${({ drawerWidth }) => (drawerWidth ? drawerWidth + 14 + 'px' : '')};
  z-index: 9;
  display: ${({ show }) => (show ? 'block' : 'none')};
  transition: all 0.5s ease-in-out;
`

export const AddNoteButton = ({ drawerWidth, show }: AddNoteButtonProps) => {
  const { dispatch, getStageCenter } = useBoardUpdater()

  const handleOnClick = () => {
    dispatch({
      type: ActionTypes.ADD_NODE,
      payload: {
        id: 'text',
        name: 'Nota',
        text: 'Escreva uma nota',
        type: 'text',
        position: getStageCenter(),
      },
    })
  }
  return (
    <StyledAddNoteButton drawerWidth={drawerWidth} show={show}>
      <IconButton color="inherit" onClick={handleOnClick}>
        <img src={AddNoteSVG} alt="back" height={60} />
      </IconButton>
    </StyledAddNoteButton>
  )
}
