/* eslint-disable react/prop-types */
import { KonvaEventObject } from 'konva/lib/Node'
import React, { useState } from 'react'
import { Arrow, Circle, Image } from 'react-konva'

import DeleteIcon from '~/assets/images/delete_icon.svg'

import { ActionTypes, useBoardUpdater } from '../../context/BoardProvider'
import useLoadImage from '../../hooks/useLoadImage'

const getPositionBetweenPoints = (points: number[]) => {
  const [x1, y1, x2, y2] = points
  const positionX = (x1 + x2) / 2
  const positionY = (y1 + y2) / 2
  return { x: positionX, y: positionY }
}

export default function LineConnection({
  onClick,
  isArrowSelected,
  points,
  nodeId,
  matchId,
}: {
  onClick: (evt?: KonvaEventObject<MouseEvent>) => void
  isArrowSelected: boolean
  points: number[]
  nodeId: string
  matchId: string
}) {
  const { dispatch } = useBoardUpdater()
  const image = useLoadImage({ src: DeleteIcon })
  const menuPosition = getPositionBetweenPoints(points)
  const [isDeleteHover, setIsDeleteHover] = useState(false)

  function removeConnection() {
    onClick(undefined)
    dispatch({
      type: ActionTypes.REMOVE_CONNECTION,
      payload: {
        id: nodeId,
        matchId,
      },
    })
  }

  return (
    <>
      <Arrow
        points={points}
        stroke={isDeleteHover ? 'red' : '#4C6FFF'}
        fill={isDeleteHover ? 'red' : '#4C6FFF'}
        hitStrokeWidth={22}
        onClick={() => onClick()}
        onMouseEnter={(e) => {
          const container = e.target.getStage()?.container()
          if (container) {
            container.style.cursor = 'pointer'
          }
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()?.container()
          if (container) {
            container.style.cursor = 'default'
          }
        }}
      />
      {isArrowSelected && (
        <>
          <Circle
            x={menuPosition.x}
            y={menuPosition.y}
            fill={isDeleteHover ? 'red' : '#4C6FFF'}
            radius={12}
            perfectDrawEnabled={false}
            cornerRadius={[2, 2, 0, 0]}
          />
          <Image
            x={menuPosition.x - 8}
            y={menuPosition.y - 8}
            width={16}
            height={16}
            onClick={removeConnection}
            image={image}
            onMouseEnter={(e) => {
              const container = e.target.getStage()?.container()
              if (container) {
                setIsDeleteHover(true)
                container.style.cursor = 'pointer'
              }
            }}
            onMouseLeave={(e) => {
              const container = e.target.getStage()?.container()
              if (container) {
                setIsDeleteHover(false)
                container.style.cursor = 'default'
              }
            }}
          />
        </>
      )}
    </>
  )
}
