/* eslint-disable react/prop-types */
import { Box, Button } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import OpenSidebarIcon from '@material-ui/icons/KeyboardArrowRight'
import SaveIcon from '@material-ui/icons/Save'
import clsx from 'clsx'
import React from 'react'
import { useHistory } from 'react-router-dom'

import BACK_ICON from '~/assets/images/back.svg'
import { AddButton } from '~/components/Buttons'
import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import * as canvasServices from '~/services/canvasServices'

import { AddNoteButton } from '../../components/AddNoteButton'
import EditCanvasName from '../../components/EditCanvasName'
import { FunnelIntegration } from '../../components/FunnelIntegration'
import { useBoardState } from '../../context/BoardProvider'
import { SidebarLeft } from './SidebarLeft'
import { SidebarRight } from './SidebarRight'

const drawerWidth = 350

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
    // position: 'relative',
    background: 'white',
    color: '#292A2A',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    position: 'absolute',
    top: '70px',
    left: '24px',
    zIndex: '99999',
    background: 'white',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    '&:hover': {
      backgroundColor: '#e9e9e9',
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
    top: '64px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px 0px 24px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: '64px',
    overflow: 'hidden',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    overflow: 'hidden',
  },
  icons: {
    color: '#50525E',
  },
}))

export function BoardWrapper({ children }) {
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const history = useHistory()
  const {
    board: { id, name, nodes },
    elementSelected,
  } = useBoardState()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)
  const element = nodes.find((el) => el.id === elementSelected?.id)
  const isValidSidebarRight = ['page', 'event'].includes(element?.type ?? '')

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const saveBoard = () => {
    dispatch({
      type: TOGGLE_LOADING,
      payload: { show: true, background: true },
    })
    canvasServices
      .update(id, { name, nodes })
      .then(() => {
        dispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Board salvo com sucesso',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        dispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Houve um problema. Tenta por favor mais tarde!',
            variant: 'error',
          },
        })
      })
      .finally(() => {
        dispatch({
          type: TOGGLE_LOADING,
          payload: { show: false, background: true },
        })
      })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" alignItems="center" flex={1}>
            <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="1rem">
              <Button
                variant="outlined"
                // color="secondary"
                // className={classes.button}
                onClick={() => history.push(`/boards`)}
                startIcon={<img src={BACK_ICON} alt="back" />}
              >
                Sair
              </Button>
              <EditCanvasName />
            </Box>
            <Box display="flex" gridGap="1rem">
              <FunnelIntegration />
              <AddButton color="inherit" onClick={saveBoard} fit Icon={SaveIcon}>
                Salvar
              </AddButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6" noWrap align="left">
            Seleção de componentes
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <SidebarLeft />
      </Drawer>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton, open && classes.hide)}
      >
        <OpenSidebarIcon className={clsx(classes.icons)} />
      </IconButton>
      <AddNoteButton drawerWidth={drawerWidth} show={open} />
      <Drawer
        variant="persistent"
        anchor="right"
        open={!!elementSelected && isValidSidebarRight}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div
          className={classes.drawerHeader}
          style={{ borderBottom: '1px solid rgb(229, 232, 245)' }}
        >
          <Typography variant="h6" noWrap align="left">
            Elemento
          </Typography>
        </div>
        <SidebarRight />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {children}
      </main>
    </div>
  )
}
