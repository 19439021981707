import { Typography } from '@material-ui/core'
import React from 'react'

import ModalComponent from '~/components/Modal'

interface ConfirmationModalProps {
  isOpen: boolean
  handleClose: () => void
  onClickConfirmation: () => void
}

export function ConfirmationModal({
  isOpen,
  handleClose,
  onClickConfirmation,
}: ConfirmationModalProps) {
  const buttons = [
    {
      variant: 'outlined',
      name: 'Cancelar',
      handleSubmit: handleClose,
    },
    {
      variant: 'contained',
      name: 'Confirmar',
      handleSubmit: onClickConfirmation,
    },
  ]

  return (
    <ModalComponent
      actions={{ handleClose }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      state={{ open: isOpen, buttons, title: 'Compartilhar Funil' }}
    >
      <Typography variant="h6" gutterBottom>
        Tem certeza que deseja prosseguir?
      </Typography>
    </ModalComponent>
  )
}
