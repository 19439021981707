import { Node as NodeType } from '~/api/canvas/types'
import { PageMetrics } from '~/api/funnels/types'

export * from './eventTypes'
export * from './pageTypes'

export const resetPageId = (nodes: NodeType[]) => {
  const nodesCopy = [...nodes]

  const nodesWithoutPageId = nodesCopy.map((node) => {
    if (node.pageId) delete node.pageId
    return node
  })

  return nodesWithoutPageId
}

export const getMetrics = (
  funnelPageMetrics: PageMetrics[] | null,
  pageId?: string,
): PageMetrics | undefined => funnelPageMetrics?.find((metric) => String(metric.id) === pageId)
