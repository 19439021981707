import { CanvasDto } from './dto'
import { Canvas } from './types'

export function dtoToCanvas(dto: CanvasDto): Canvas {
  return {
    id: dto.id,
    funnelId: dto.funnel_id,
    name: dto.name,
    nodes: dto.nodes,
  }
}
