import { KonvaEventObject } from 'konva/lib/Node'
import { Image } from 'konva/lib/shapes/Image'
import { Vector2d } from 'konva/lib/types'
import React, { useState } from 'react'
import { Arrow, Layer, Line, Stage } from 'react-konva'
import styled from 'styled-components'

import { Node as NodeType } from '~/api/canvas/types'

import { EditableText } from '../../components/EditableText/EditableText'
import LineConnection from '../../components/LineConnection'
import { Node, NodePage } from '../../components/Node'
import ZoomControl from '../../components/ZoomControl'
import { EVENT_TYPE, PAGE_TYPE } from '../../constants/actions'
import { ELEMENT_EVENT, ELEMENT_PAGE } from '../../constants/elementsType'
// import { RANGE, SCALE_BY } from '../../constants/scale'
import {
  ActionTypes,
  getElementIndex,
  Position,
  useBoardState,
  useBoardUpdater,
} from '../../context/BoardProvider'
import { BoardWrapper } from './BoardWrapper'

const StageStyled = styled(Stage)`
  background: radial-gradient(circle, #dce2f9 10%, transparent 11%);
  background-size: 1em 1em;
  background-color: #f7f8fc;
  opacity: 1;
`

function createConnectionPoints(source: Vector2d, destination: Vector2d) {
  return [source.x, source.y, destination.x, destination.y]
}

function hasIntersection(position: Position, step: Position) {
  return !(
    step.x > position.x ||
    step.x + ELEMENT_PAGE.dimension.w < position.x ||
    step.y > position.y ||
    step.y + ELEMENT_PAGE.dimension.h < position.y
  )
}

function detectConnection({
  position,
  id,
  elements,
  cardsRef,
  getPointWithScale,
}: {
  position: Position
  id: string
  elements: NodeType[]
  cardsRef: React.MutableRefObject<{
    [x: string]: Image
  }>
  getPointWithScale: (pointer: Position) => Position
}) {
  const mousePosWithScale = getPointWithScale(position)
  const elementOrigen = elements.find((el) => el.id === id)

  const intersectingStep = elements.find((el) => {
    const nextCardWithScale = getPointWithScale(cardsRef?.current[el.id].getAbsolutePosition())
    return el?.id !== id && hasIntersection(mousePosWithScale, nextCardWithScale)
  })
  const isDuplicatedConnection =
    intersectingStep && elementOrigen?.match?.some((matchId) => matchId === intersectingStep?.id)

  if (!isDuplicatedConnection) {
    return intersectingStep
  }
  return null
}

const Board = () => {
  const [connectionPreview, setConnectionPreview] = useState<React.JSX.Element | null>(null)
  const {
    board: { nodes },
    stageRef,
    elementSelected,
    funnelPageMetrics,
    funnel,
  } = useBoardState()
  const { dispatch, getPointWithScale, setElementSelected } = useBoardUpdater()

  const cardsRef = React.useRef<{ [x: string]: Image }>({})
  const [arrowSelectedId, setArrowSelectedId] = useState<string | null>(null)
  const [percentil, setPercentil] = useState(100)

  const setConnections = (id: string, to: string) => {
    dispatch({
      type: ActionTypes.ADD_CONNECTION,
      payload: {
        id,
        to,
      },
    })
  }

  const handleOnDragMove = (elementRef: NodeType, e: KonvaEventObject<DragEvent>) => {
    const elementsCopy = [...nodes]
    const elementIndex = elementsCopy.findIndex((el) => el.id === elementRef.id)
    elementsCopy[elementIndex] = {
      ...elementsCopy[elementIndex],
      position: {
        x: e.target.x(),
        y: e.target.y(),
      },
    }
    dispatch({
      type: ActionTypes.UPDATE_POSITIONS,
      payload: elementsCopy,
    })
  }

  const handleElementClick = (element: NodeType) => {
    setElementSelected(element)
  }

  const generateLinePoints = (from: NodeType, to: NodeType) => {
    const getRectCenter = (type: NodeType['type'], x: number = 0, y: number = 0) => {
      const dimension = type === EVENT_TYPE ? ELEMENT_EVENT.dimension : ELEMENT_PAGE.dimension
      return {
        x: x + dimension.w / 2,
        y: y + dimension.h / 2,
      }
    }

    const A = getRectCenter(from?.type, from?.position?.x, from?.position?.y)
    const B = getRectCenter(to?.type, to?.position?.x, to?.position?.y)
    const dx = B.x - A.x
    const dy = B.y - A.y
    const angle = Math.atan2(-dy, dx)

    const radius = 70

    return [
      A.x + -radius * Math.cos(angle + Math.PI),
      A.y + radius * Math.sin(angle + Math.PI),
      B.x + -radius * Math.cos(angle),
      B.y + radius * Math.sin(angle),
    ]
  }

  function handleAnchorDragStart(e: KonvaEventObject<DragEvent>) {
    const position = e.target.position()
    setConnectionPreview(
      <Line
        x={position.x}
        y={position.y}
        points={createConnectionPoints(position, position)}
        stroke="black"
        strokeWidth={2}
      />,
    )
  }

  function handleAnchorDragMove(e: KonvaEventObject<DragEvent>) {
    const position = e.target.position()
    const mousePos = e.target.getRelativePointerPosition()
    setConnectionPreview(
      <Arrow
        x={position.x}
        y={position.y}
        points={createConnectionPoints({ x: 0, y: 0 }, mousePos)}
        stroke={'#4C6FFF'}
        strokeWidth={2}
        fill={'#4C6FFF'}
      />,
    )
  }

  function handleArrowSelected(arrowId: string) {
    setArrowSelectedId(arrowId)
  }

  function handleAnchorDragEnd(e: KonvaEventObject<DragEvent>, id: string) {
    setConnectionPreview(null)
    const stage = stageRef.current
    const mousePos = stage?.getPointerPosition()
    const nodeWithoutTextTypes = nodes.filter((n) => n.type !== 'text')

    const connectionTo = detectConnection({
      position: mousePos as Position,
      id,
      elements: nodeWithoutTextTypes,
      cardsRef,
      getPointWithScale,
    })
    if (connectionTo) {
      setConnections(id, connectionTo.id)
    }
  }

  // const handleWheel = (e) => {
  //   console.log('handleWheel')
  //   // stop default scrolling
  //   e.evt.preventDefault()
  //   e.evt.stopPropagation()
  //   e.evt.cancelBubble = true

  //   const stage = stageRef.current
  //   const oldScale = stage.scaleX()
  //   const pointer = stage.getPointerPosition()

  //   const mousePointTo = {
  //     x: (pointer.x - stage.x()) / oldScale,
  //     y: (pointer.y - stage.y()) / oldScale,
  //   }

  //   // how to scale? Zoom in? Or zoom out?
  //   let direction = e.evt.deltaY > 0 ? 1 : -1

  //   // when we zoom on trackpad, e.evt.ctrlKey is true
  //   // in that case lets revert direction
  //   if (e.evt.ctrlKey) {
  //     direction = -direction
  //   }

  //   const newScale = direction > 0 ? oldScale + SCALE_BY : oldScale - SCALE_BY

  //   let scale = newScale
  //   if (newScale < RANGE.MIN) scale = RANGE.MIN
  //   if (newScale > RANGE.MAX) scale = RANGE.MAX

  //   stage.scale({ x: scale, y: scale })
  //   setPercentil(scale * 100)

  //   const newPos = {
  //     x: pointer.x - mousePointTo.x * scale,
  //     y: pointer.y - mousePointTo.y * scale,
  //   }
  //   stage.position(newPos)
  // }

  const handleStageOnClick = (e: KonvaEventObject<MouseEvent>) => {
    const stage = stageRef.current
    // prevent default behavior
    e.evt.preventDefault()
    if (e.target === stage) {
      setArrowSelectedId(null)
      setElementSelected(null)
    }
  }

  const pageOrAction = (elementBase: NodeType, type: string) => {
    const { pageId } = elementBase
    const ComponentNode = type === EVENT_TYPE ? Node : NodePage
    const { image } = funnel?.pages?.find((page) => String(page.id) === pageId) ?? {}
    const { metrics } = funnelPageMetrics?.find((metric) => String(metric.id) === pageId) ?? {}

    const element = { ...elementBase, ...(image ? { pageImage: image } : {}) }

    return (
      <React.Fragment key={`page_${element.id}`}>
        <ComponentNode
          element={element}
          isSelected={element.id === elementSelected?.id}
          metrics={metrics}
          onDragMove={(e) => handleOnDragMove(element, e)}
          handleClick={() => handleElementClick(element)}
          handleAnchorDragEnd={(e) => handleAnchorDragEnd(e, element.id)}
          handleAnchorDragMove={handleAnchorDragMove}
          handleAnchorDragStart={handleAnchorDragStart}
          ref={(el) => {
            if (el) cardsRef.current[element.id] = el
          }}
        />
        {element.match &&
          element.match.map((matchId) => {
            const index = getElementIndex(matchId, nodes)
            const elementTarget = nodes[index]
            const arrowId = `${element.id}_${elementTarget.id}`
            const isArrowSelected = !!arrowSelectedId && arrowId === arrowSelectedId

            return (
              elementTarget && (
                <React.Fragment key={arrowId}>
                  <LineConnection
                    // onClick={(args) => handleArrowSelected(args !== undefined ? args : arrowId)}
                    onClick={() => handleArrowSelected(arrowId)}
                    isArrowSelected={isArrowSelected}
                    points={generateLinePoints(element, elementTarget)}
                    nodeId={element.id}
                    matchId={matchId}
                  />
                </React.Fragment>
              )
            )
          })}
      </React.Fragment>
    )
  }

  return (
    <BoardWrapper>
      <ZoomControl stageRef={stageRef} percentil={percentil} setPercentil={setPercentil} />
      <StageStyled
        ref={stageRef}
        width={window.innerWidth}
        height={window.innerHeight - 64} // 64px is the Topbar height
        // onWheel={handleWheel}
        onClick={handleStageOnClick}
        draggable
      >
        <Layer>
          {nodes.map((element) => {
            if (element.type === 'event') {
              return pageOrAction(element, EVENT_TYPE)
            } else if (element.type === 'page') {
              return pageOrAction(element, PAGE_TYPE)
            } else if (element.type === 'text') {
              return (
                <EditableText
                  onDragMove={(e) => handleOnDragMove(element, e)}
                  isSelected={element.id === elementSelected?.id}
                  handleClick={() => handleElementClick(element)}
                  key={element.id}
                  element={element}
                />
              )
            }
          })}
          {connectionPreview}
        </Layer>
      </StageStyled>
    </BoardWrapper>
  )
}

export default Board
