/* eslint-disable react/prop-types */
import React from 'react'
import { Image } from 'react-konva'

import CopyIcon from '~/assets/images/copy_icon.svg'

import { ActionTypes, useBoardUpdater } from '../../context/BoardProvider'
import useLoadImage from '../../hooks/useLoadImage'

function Copy({ x, y, id }: { x: number; y: number; id: string }) {
  const image = useLoadImage({ src: CopyIcon })
  const { dispatch, getStageCenter } = useBoardUpdater()
  const width = 18

  const handleOnClick = () => {
    dispatch({ type: ActionTypes.COPY_NODE, payload: { id, position: getStageCenter() } })
  }

  return (
    <>
      <Image
        x={x + 24}
        y={y - width}
        width={width}
        height={width}
        onClick={handleOnClick}
        cornerRadius={2}
        image={image}
        onMouseEnter={(e) => {
          const container = e.target.getStage()?.container()
          if (container) {
            container.style.cursor = 'pointer'
          }
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()?.container()
          if (container) {
            container.style.cursor = 'default'
          }
        }}
      />
    </>
  )
}

export default Copy
