import { AxiosError } from 'axios'

import { SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'

function useAlert() {
  const dispatch = useAppDispatch()

  const setAlert = (msg: string, variant: 'error' | 'success') => {
    dispatch({
      type: SET_ALERT,
      payload: {
        open: true,
        message: msg,
        variant: variant,
      },
    })
  }

  const getDataError = (error: AxiosError) => error.response?.data?.error

  return {
    setAlert,
    getDataError,
  }
}

export { useAlert }
